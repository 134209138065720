import './widget.packeta.com_v6_www_js_library-1.0.6'
import * as bootstrap from 'bootstrap';
import naja from 'naja';
import {LiveForm, Nette} from 'live-form-validation';

function cartSubmitInit() {
    const cart = document.getElementById('cart');

    if (cart) {
        cart.addEventListener('change', (event) => {
            const input = event.target;

            if (input.type === 'number' || input.type === 'text') {
                naja.uiHandler.submitForm(input.form);
            }
        });
    }
}

function paymentSubmitInit() {
    const payment = document.getElementById('payment');
    const toDelivery = document.getElementById('to-delivery');

    if (payment) {
        payment.addEventListener('change', (event) => {
            const input = event.target;
            const isPickUpPoint = input.classList.contains('pickup-point');

            if (isPickUpPoint === false && input.dataset.ajaxOff === undefined && (input.type === 'number' || input.type === 'text' || input.type === 'radio')) {
                naja.uiHandler.submitForm(input.form);
            }
        });
        if (toDelivery) {
            toDelivery.addEventListener('click', (event) => {
                if (!Nette.validateForm(payment)) {
                    event.preventDefault();
                    return false;
                }
            });
        }
    }
}

const packetaExtension = {
    initialize(naja) {
        naja.snippetHandler.addEventListener('afterUpdate', (event) => {
            const {snippet} = event.detail;

            if (snippet.id === 'snippet--payment') {
                packeta.init();
                paymentSubmitInit();
                const toastElList = [].slice.call(document.querySelectorAll('.toast'))
                const toastList = toastElList.map(function (toastEl) {
                    return new bootstrap.Toast(toastEl, {
                        animation: true,
                        autohide: false
                    })
                });
                toastList.forEach(function (toast) {
                    toast.show();
                });
            } else if (snippet.id === 'snippet--cartwidget' || snippet.id === 'snippet--cart') {
                cartSubmitInit();
            }
        });
    }
}

naja.registerExtension(packetaExtension);
naja.initialize();

cartSubmitInit();
paymentSubmitInit();

LiveForm.setOptions({
    messageErrorPrefix: '',
    messageErrorClass: ['invalid-feedback d-block order-last'],
    messageTag: 'div',
    showMessageClassOnParent: 'validation-container',
    messageParentClass: 'validation-container',
    controlErrorClass: 'is-invalid',
    controlValidClass: 'is-valid'
});

Nette.initOnLoad();
window.Nette = Nette;
window.LiveForm = LiveForm;

let stickyNavigation = document.getElementById('sticky-navigation');
let lastScrollTop = 0;
let height = stickyNavigation.offsetHeight;

window.addEventListener('scroll', function(){
    let st = window.scrollY || document.documentElement.scrollTop;

    if (st > lastScrollTop) {
        if (st > height) {
            if (stickyNavigation.classList.contains('fixed-top')) {
                stickyNavigation.classList.remove('fixed-top');
            }
        }
    } else {
        if (st > height/2) {
            stickyNavigation.classList.add('fixed-top');
        } else {
            stickyNavigation.classList.remove('fixed-top');
        }
    }

    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}, false);

// PACKETA
const packeta = {
    init: function (packetaWidgetOpeners) {
        if (packetaWidgetOpeners === undefined || packetaWidgetOpeners === null) {
            packetaWidgetOpeners = document.getElementsByClassName('pickup-point');
        }

        for (var i = 0; i < packetaWidgetOpeners.length; i++) {
            let packetaWidgetOpener = packetaWidgetOpeners[i];
            packetaWidgetOpener.addEventListener('click', (event) => {
                let packetaApiKey = '869a2eabcaf1f02f';
                let widgetOptions = {
                    appIdentity: 'hlbavo-alpha-0.1',
                    country: 'sk',
                    language: 'sk',
                };
                let input = event.target;

                Packeta.Widget.pick(packetaApiKey, function (pickupPoint)
                {
                    let form = input.closest('form');
                    let container = input.dataset.container;
                    let key = input.dataset.key;

                    if (form !== null && container !== null) {
                        let id = form.querySelector('[name="' + input.dataset.container + '[id]"]');
                        let name = form.querySelector('[name="' + input.dataset.container + '[name]"]');
                        let currency = form.querySelector('[name="' + input.dataset.container + '[currency]"]');
                        let pickupPointType = form.querySelector('[name="' + input.dataset.container + '[pickupPointType]"]');
                        let carrierId = form.querySelector('[name="' + input.dataset.container + '[carrierId]"]');
                        let carrierPickupPointId = form.querySelector('[name="' + input.dataset.container + '[carrierPickupPointId]"]');

                        if (pickupPoint != null) {
                            id.value = pickupPoint.id;
                            name.value = pickupPoint.name;
                            currency.value = pickupPoint.currency;
                            pickupPointType.value = pickupPoint.pickupPointType;
                            carrierId.value = pickupPoint.carrierId;
                            carrierPickupPointId.value = pickupPoint.carrierPickupPointId;

                            if (key !== null) {
                                document.getElementById('pickup-point-address-' + key).textContent = pickupPoint.name;
                            }

                            naja.uiHandler.submitForm(input.form);
                        } else {
                            if (id.value === '') {
                                input.checked = false;
                            }
                        }
                    }
                }, widgetOptions);
            });
        }
    }
};

window.packeta = packeta;